// ==============================
// * PAGE DESIGNER: CONTENT SEE MORE ITEM
//
// ? This file gathers all the custom content see more item CSS.
// ==============================

@import "../../variables";
@import "../../experienceGlobal";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";


.seemore {
    &__content {
        &.text-small-size {
            font-size: $font-size-sm;
        }

        &--text {
            display: none;
        }

        &--btn {
            .seeless {
                display: none;
            }

            &.show {
                .seeless {
                    display: block;
                }

                .seemore {
                    display: none;
                }
            }
        }
    }
}
